import React, { useEffect } from 'react';
import './TikTokVideoRow.css'
import TopBar from './TopBar';


const TikTokVideoRow = () => {

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://www.tiktok.com/embed.js";
        script.async = true;
        document.body.appendChild(script);
    
        return () => {
          document.body.removeChild(script);
        };
      }, []);

  return (  
    <div className="video-row"> 
    <blockquote 
    class="tiktok-embed" 
    cite="https://www.tiktok.com/@monthlypicss/video/7358212762188762411" 
    data-video-id="7358212762188762411" 
    style={{ maxWidth: "325px", minWidth: "325px" }}>
    <section> 
        <a target="_blank" title="@monthlypicss" href="https://www.tiktok.com/@monthlypicss?refer=embed">@monthlypicss</a> 
        I love getting my photos every month. In a world where everything is now digital, it is a breath of fresh air to have physical photos around the house. 10&#47;10 recommend for yourself or as gifts 
        <a title="familyphotos" target="_blank" href="https://www.tiktok.com/tag/familyphotos?refer=embed">#familyphotos</a> 
        <a title="favoritephoto" target="_blank" href="https://www.tiktok.com/tag/favoritephoto?refer=embed">#favoritephoto</a> 
        <a title="memoriesareforever" target="_blank" href="https://www.tiktok.com/tag/memoriesareforever?refer=embed">#memoriesareforever</a> 
        <a target="_blank" title="♬ Love You So - The King Khan &#38; BBQ Show" href="https://www.tiktok.com/music/Love-You-So-7096858059892328450?refer=embed">♬ Love You So - The King Khan &#38; BBQ Show</a> 
    </section> 
    </blockquote> 
    <script async src="https://www.tiktok.com/embed.js"></script>


    <blockquote 
        class="tiktok-embed" 
        cite="https://www.tiktok.com/@monthlypicss/video/7358497384264994091" 
        data-video-id="7358497384264994091" 
        style={{ maxWidth: "325px", minWidth: "325px" }}>
        <section> 
        <a target="_blank" title="@monthlypicss" href="https://www.tiktok.com/@monthlypicss?refer=embed">@monthlypicss</a> 
        What’s harder, getting your kids to smile in photos, or your spouse? 
        <a title="photoshoot" target="_blank" href="https://www.tiktok.com/tag/photoshoot?refer=embed">#photoshoot</a> 
        <a title="familyphotos" target="_blank" href="https://www.tiktok.com/tag/familyphotos?refer=embed">#familyphotos</a> 
        <a title="familypictures" target="_blank" href="https://www.tiktok.com/tag/familypictures?refer=embed">#familypictures</a> 
        <a title="favoritephoto" target="_blank" href="https://www.tiktok.com/tag/favoritephoto?refer=embed">#favoritephoto</a> 
        <a title="memoriesareforever" target="_blank" href="https://www.tiktok.com/tag/memoriesareforever?refer=embed">#memoriesareforever</a> 
        <a target="_blank" title="♬ NOTHIN TO IT by ON THE HUNT and Chandler - Chandler" href="https://www.tiktok.com/music/NOTHIN-TO-IT-by-ON-THE-HUNT-and-Chandler-7351114737725213486?refer=embed">♬ NOTHIN TO IT by ON THE HUNT and Chandler - Chandler</a> 
        </section> 
        </blockquote> 


    <blockquote 
    className="tiktok-embed" 
    cite="https://www.tiktok.com/@monthlypicss/video/7359363038685760814" 
    data-video-id="7359363038685760814" 
    style={{ maxWidth: "325px", minWidth: "325px" }}> 
    <section> 
        <a target="_blank" title="@monthlypicss" href="https://www.tiktok.com/@monthlypicss?refer=embed">@monthlypicss</a> 
        Free photos + free frame? Yes, please 
        <a title="photoshoot" target="_blank" href="https://www.tiktok.com/tag/photoshoot?refer=embed">#photoshoot</a> 
        <a title="familyphotos" target="_blank" href="https://www.tiktok.com/tag/familyphotos?refer=embed">#familyphotos</a> 
        <a title="favoritephoto" target="_blank" href="https://www.tiktok.com/tag/favoritephoto?refer=embed">#favoritephoto</a> 
        <a title="photocards" target="_blank" href="https://www.tiktok.com/tag/photocards?refer=embed">#photocards</a> 
        <a target="_blank" title="♬ original sound - SILLYBALOO" href="https://www.tiktok.com/music/original-sound-7354573502181100330?refer=embed">♬ original sound - SILLYBALOO</a> 
    </section> 
    </blockquote> 

</div>
 
  );
}

export default TikTokVideoRow
