import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import './SignInForm.css'; 
import { auth } from '../../firebase/firebaseConfig';

const SignInForm = ({ onClose }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

 // Function to modify the email by inserting '1' before the '@'
    function modifyEmail(originalEmail) {
    const parts = originalEmail.split('@');
    if (parts.length === 2) { // Ensure the email is valid with exactly one '@'
        return `${parts[0]}1@${parts[1]}`;
    }
    return originalEmail; // Return original if it doesn't meet expected format
}


    const handleSubmit = async (e) => {
        e.preventDefault();
        const modifiedEmail = modifyEmail(email);
    
        try {
            await signInWithEmailAndPassword(auth, modifiedEmail, password);
            onClose();  // Assuming onClose is a function to close the modal or redirect the user
        } catch (err) {
            setError('Failed to sign in. Please check your email and password.');
        }
    };

    return (
        <div style={{marginTop: -200}}> 
            <form onSubmit={handleSubmit}>
                <label style={{
                    textAlign: 'start',
                    fontSize: 18,
                    fontWeight: '600'
                    }}>Email:</label>
                <input type="email" value={email} onChange={handleEmailChange} required />
                <label style={{
                    textAlign: 'start',
                    fontSize: 18,
                    fontWeight: '600'
                    }} >Password:</label>
                <input type="password" value={password} onChange={handlePasswordChange} required />
                {error && <p className="error">{error}</p>}
                <button type="submit">Sign In</button>
                <button style={{
                    backgroundColor: '#232323',
                    marginTop: 15,
                    }} type="button" onClick={onClose}>Back</button>
            </form>
        </div>
    );
};

export default SignInForm;
