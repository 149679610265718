import React from 'react';
import { BrowserRouter as Router, Routes, Route, } from 'react-router-dom';
import './App.css';

import ContactUsPage from './pages/ContactUsPage/ContactUsPage';
import FAQPage from './pages/FAQ/FAQPage';
import LandingPage from './pages/LandingPage/LandingPage';
import ProductUpload from './pages/ProductUpload/ProductUpload';
import Recipient from './pages/Recipient/recipient';
import Affiliate from './pages/Affiliate/Affiliate';
import Dashboard from './pages/Affiliate/Dashboard';
import ContentGuidelines from './pages/Affiliate/ContentGuidelines';
import Settings from './pages/Affiliate/Settings';
import BottomTabBar from './tabs/BottomTabBar';
import TopTabBar from './tabs/TopTabBar';
import Redirect from './pages/Redirect/Redirect';
import ContentGuidelinesNew from './pages/Affiliate/ContentGuidelinesNew';
import ImageHost from './pages/PagesHosting/ImageHost';



function App() {

  return (
    <Router>
      <div className="App">
            <TopTabBar />
            <div className="container">
              <Routes>
                <Route path="/" element={<LandingPage />}/>
                <Route path="/App" element={<Redirect />}/>
                <Route path="/LandingPage" element={<LandingPage />} />
                <Route path="/faq" element={<FAQPage />} />
                <Route path="/contact" element={<ContactUsPage />} />
                <Route path="/productUpload" element={<ProductUpload />} /> 
                <Route path="/recipient/:senderUID/:pictureIndex/:firstName/:lastName" element={<Recipient/>} />
                <Route path="/Affiliate" element={<Affiliate />} /> 
                <Route path="/Dashboard" element={<Dashboard />} /> 
                <Route path="/ContentGuidelinesNew" element={<ContentGuidelinesNew />} /> 
                <Route path="/settings" element={<Settings />} />
                <Route path="/ImageHost" element={<ImageHost />} />
              </Routes>
            </div>
        </div>
         <BottomTabBar />
      
    </Router>
  );
}

export default App;
