import React from 'react';
import './HeroSection.css';

import card from '../../../Images/card.png';
import envelope from '../../../Images/envelope.png';

const HeroSection = () => {


  const SendToAppropriateAppStore = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    let appStoreLink;

    if (/android/i.test(userAgent)) {
        appStoreLink = 'https://play.google.com/store/apps/details?id=yourapp.package';
        window.open(appStoreLink, '_blank'); // Opens in a new tab
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        appStoreLink = 'https://apps.apple.com/app/id6471882563';
        window.open(appStoreLink, '_blank'); // Opens in a new tab
    } else {
        appStoreLink = 'https://apps.apple.com/us/app/monthlypics-pic-print-collage/id6471882563';
        window.open(appStoreLink, '_blank'); // Opens in a new tab
    }
}



    return(
        <header className="hero-section">
        <div className="hero-content">
          <div className="hero-background-image-left">
            <img src={card} alt="Decorative background" />
          </div>
          <div className="hero-text" style={{marginTop: -300}}>
            <h1>Get Your Best Photos Every Month</h1>
            <p>3 High Quality 4x6 Photos</p>
            <p>Send Your Favorite Photo to Friends</p>
          </div>
        <div className='button-section'>
          <button 
                className="call-to-action"
                onClick={() => SendToAppropriateAppStore()}>
                Download App
            </button>
        </div>
          <div className="hero-background-image-right">
            <img src={envelope} alt="Decorative background" />
          </div>
        </div>
      </header>
    )
}

export default HeroSection