import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase/firebaseConfig';
import './recipient.css'; 

function Recipient() {
  const { senderUID, firstName: initialFirstName, lastName: initialLastName } = useParams();
  const [firstName, setFirstName] = useState(initialFirstName);
  const [lastName, setLastName] = useState(initialLastName);
  const [address, setAddress] = useState('');
  const [apartmentNumber, setApartmentNumber] = useState('');
  const [recipientNotFound, setRecipientNotFound] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');
  const addressInputRef = useRef(null);

  useEffect(() => {
    const fetchRecipientInfo = async () => {
      const userDocRef = doc(firestore, 'users', senderUID);
      const docSnap = await getDoc(userDocRef);

      if (docSnap.exists()) {
        const userData = docSnap.data();
        let found = false;

        for (const picture of userData.selectedPicture || []) {
          const recipient = picture.gemRecipients?.find(r => r.firstName === initialFirstName && r.lastName === initialLastName);
          if (recipient) {
            const recipientAddress = recipient.address === "Address Pending..." ? '' : recipient.address;
            setAddress(recipientAddress); 
            found = true;
            break; 
          }
        }
        setRecipientNotFound(!found); 
      }
    };

    fetchRecipientInfo();
  }, [senderUID, initialFirstName, initialLastName]);

  useEffect(() => {
    const initializeAutocomplete = () => {
      if (!window.google) {
        console.error("Google Maps JavaScript API not loaded");
        return;
      }

      // Initialize Google Places Autocomplete
      new window.google.maps.places.Autocomplete(addressInputRef.current, {
        types: ['address'],
        componentRestrictions: { country: "us" },
      }).addListener('place_changed', function () {
        const place = this.getPlace();
        setAddress(place.formatted_address);
      });
    };

    // Ensure the Google Places API is loaded before initializing
    if (window.google) {
      initializeAutocomplete();
    } else {
      window.initAutocomplete = initializeAutocomplete;
    }
  }, []);

/*   const handleSubmit = async (e) => {
    e.preventDefault();
    updateRecipientInformation(address)

  }
 */

   const handleSubmit = async (e) => {
    e.preventDefault();

    let fullAddress = address;
    if (apartmentNumber.trim() !== '') {
      fullAddress = `${fullAddress}, Apt ${apartmentNumber}`;
    }

    updateRecipientInformation(fullAddress);

/*      const apiKey = 'AIzaSyDGnIWTO6ca8kMTrKarW-hLtKsUUC_v_DI'
    const addressForAPI = address.replace(' ', '+');
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${addressForAPI}&key=${apiKey}`;

    try {
      const response = await fetch(apiUrl);
      const data = await response.json();

      if (data.status === 'OK') {
        // Assume first result is the correct one
        const formattedAddress = data.results[0].formatted_address;
        // Use formatted address or perform further actions
        console.log(formattedAddress); // For demonstration
        updateRecipientInformation(formattedAddress);
      } else {
        setValidationMessage('Invalid address. Please enter a valid address.');
      }
    } catch (error) {
      console.error(error);
      setValidationMessage('Error occurred while validating the address');
    }  */
  }; 

  const updateRecipientInformation = async (formattedAddress) => {
    const userDocRef = doc(firestore, 'users', senderUID);
    const docSnap = await getDoc(userDocRef);

    if (docSnap.exists()) {
      const userData = docSnap.data();

      let updated = false;
      const updatedSelectedPicture = userData.selectedPicture.map(picture => {
        const recipients = picture.gemRecipients?.map(recipient => {
          if (recipient.firstName === initialFirstName && recipient.lastName === initialLastName) {
            updated = true; // Mark as updated
            return { ...recipient, firstName, lastName, address: formattedAddress, apartmentNumber }; // Update recipient info including apartment number
          }
          return recipient; // Return unmodified for non-matching recipients
        });

        return { ...picture, gemRecipients: recipients }; // Return updated picture
      });

      if (updated) {
        await updateDoc(userDocRef, { selectedPicture: updatedSelectedPicture });
        alert('Recipient information updated successfully!');
        setValidationMessage(''); // Clear any validation message
      } else {
        setRecipientNotFound(true); // If no update occurred, the recipient was not found
      }
    }
  };

  if (recipientNotFound) {
    return <div>Recipient not found or no longer receiving anything.</div>;
  }

  return (
    <div className="form-container">
      <h2>Update Your Information</h2>
      <form onSubmit={handleSubmit} className="info-form">
        <input
          type="text"
          placeholder="First Name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          required
          className="input-field"
        />
        <input
          type="text"
          placeholder="Last Name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          required
          className="input-field"
        />
        <input
          type="text"
          placeholder="Full Shipping Address"
          ref={addressInputRef}
          onChange={(e) => setAddress(e.target.value)}
          value={address}
          required
          className="input-field"
        />
        <input
          type="text"
          placeholder="Apartment Number (optional)"
          value={apartmentNumber}
          onChange={(e) => setApartmentNumber(e.target.value)}
          className="input-field"
        />
        {validationMessage && <p className="validation-message">{validationMessage}</p>}
        <button type="submit" className="submit-button">Submit</button>
      </form>
    </div>
  );
}

export default Recipient;
