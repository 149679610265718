import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';



const firebaseConfig = {
  apiKey: "AIzaSyBnM-cQf74fbOPA4tJZMmIWYZUBD5Cv2RE",
  authDomain: "oneamonth-7c571.firebaseapp.com",
  projectId: "oneamonth-7c571",
  storageBucket: "oneamonth-7c571.appspot.com",
  messagingSenderId: "4443101346",
  appId: "1:4443101346:web:b4d0385ba70ed1a8925ffb",
  measurementId: "G-8BN20LX1MT"
}; 

const app = initializeApp(firebaseConfig);

const firestore = getFirestore(app);

const auth = getAuth(app);

const storage = getStorage(app);



export { app, auth, firestore, storage };
