// TopBar.js
import React from 'react';
import { Link } from 'react-router-dom';
import './TopBar.css'

const TopBar = () => {
    return (
        <div className="top-bar">
            <Link to="/dashboard" className="nav-link">Dashboard</Link>
            <Link to="/ContentGuidelinesNew" className="nav-link">Content</Link>
            <Link to="/settings" className="nav-link" style={{ marginLeft: 'auto' }}>Settings</Link>
        </div>
    );
};

export default TopBar;
