import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged, signOut } from 'firebase/auth'; // Make sure to import signOut
import { auth } from '../../firebase/firebaseConfig';
import './Affiliate.css';  

import SignUpForm from './SignUpForm';
import SignInForm from './SignInForm';
import TikTokVideoRow from './TikTokVideoRow';

const Affiliate = () => {
    const [showSignUp, setShowSignUp] = useState(false);
    const [showSignIn, setShowSignIn] = useState(false);
    const [user, setUser] = useState(null);  // Initialize to null instead of {}
    const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 600);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, currentUser => {
            if (currentUser) {
                setUser(currentUser); 
                setTimeout(() => { 
                    navigate("/dashboard");
                }, 2000); 
            } else {
                setUser(null);  // Explicitly set to null when no user is signed in
            }
        });
        return () => unsubscribe();
    }, [navigate]);

    const handleSignInClick = () => {
        setShowSignIn(true);
    };

    const handleSignUpClick = () => {
        setShowSignUp(true);
    };

    const handleSignOut = async () => {
        try {
            await signOut(auth);  // Use Firebase signOut function
        } catch (error) {
            console.error('Sign Out Error', error);
        }
    };

    return (
        <div className="affiliate-landing">
            {user ? (
                <div>
                    <h1>Welcome {user.displayName || 'Guest'}!</h1>
                    <p>Redirecting you to your dashboard...</p>
                    <button onClick={handleSignOut}>Sign Out</button>  {/* Add a sign-out button for testing */}
                </div>
            ) : !showSignUp && !showSignIn ? (
                <div style={{marginTop: -200}}>
                    <h1 className='title'>Welcome to the MonthlyPics Partner Program</h1>
                    <p>Join us and start earning by promoting MonthlyPics!</p>
                    <button onClick={handleSignInClick}>Sign In</button>
                    <button onClick={handleSignUpClick}>Sign Up</button>
                </div>
            ) : showSignIn ? (
                <SignInForm onClose={() => setShowSignIn(false)} />
            ) : (
                <SignUpForm onClose={() => setShowSignUp(false)} />
            )}
        </div>
    );
};

export default Affiliate;
