import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


const Redirect = () => {
    const navigate = useNavigate();


    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        
        if (/android/i.test(userAgent)) {
            const appStoreLink = 'https://play.google.com/store/apps/details?id=yourapp.package'; 
            window.location.href = appStoreLink; 
            setTimeout(() => { 
                navigate("/LandingPage");
            }, 1000); 
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            const appStoreLink = 'https://apps.apple.com/app/id6471882563'; 
            window.location.href = appStoreLink; 
            setTimeout(() => { 
                navigate("/LandingPage");
            }, 1000);  
        } else {
            navigate("/LandingPage");

        }
    }, []);

    return (
        <div className="full-container">
        </div>
    );
};

export default Redirect;
