import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, firestore } from '../../firebase/firebaseConfig';
import { collection, getDoc, query, doc, where, getDocs, updateDoc, serverTimestamp } from 'firebase/firestore';
import './Dashboard.css'; 
import { ReactComponent as EditIcon } from './edit-icon.svg';  // Adjust the path as necessary
import TopBar from './TopBar';


const Dashboard = () => {
    const [user, setUser] = useState(null);
    const [totalSubscribers, setTotalSubscribers] = useState(0);
    const [originalLink, setOriginalLink] = useState('');
    const [isEditingLink, setIsEditingLink] = useState(false);
    const [activeSubscribers, setActiveSubscribers] = useState(0);
    const [linkCustomization, setLinkCustomization] = useState('');
    const [promoCodeUsed, setPromoCodeUsed] = useState(false);
    const [promoCode, setPromoCode] = useState('');
    const [stripeAccountId, setStripeAccountId] = useState('');

    const navigate = useNavigate();

    const monthlyPayout = activeSubscribers * 0.63
    const yearlyPayout = activeSubscribers * 0.63 * 12


    useEffect(() => {
        if (!auth.currentUser) {
            navigate("/signin");
        } else {
            fetchAffiliateData();
            checkAndGeneratePromoCode();
            //createStripeAccount()
        }
    }, []);


    const checkAndGeneratePromoCode = async () => {
        const influencerDocRef = doc(firestore, "influencers", auth.currentUser.uid);
        const docSnap = await getDoc(influencerDocRef);
        if (docSnap.exists()) {
            const data = docSnap.data();
            if (!data.promoCode) {
                // Generate promo code as Affiliate####
                const generatedPromoCode = 'affiliate' + Math.floor(1000 + Math.random() * 9000);
                await updateDoc(influencerDocRef, {
                    promoCode: generatedPromoCode,
                    promoCodeUsed: false
                });
                setPromoCode(generatedPromoCode);
            } else {
                setPromoCode(data.promoCode);
                setPromoCodeUsed(data.promoCodeUsed);
            }
        }
    };


    const fetchAffiliateData = async () => {
        if (auth.currentUser) {
            const influencerDocRef = doc(firestore, "influencers", auth.currentUser.uid);
            const docSnap = await getDoc(influencerDocRef);

            if (docSnap.exists() && docSnap.data().PromoCode) {
                setUser(docSnap.data());
                setLinkCustomization(docSnap.data().PromoCode);
                setOriginalLink(docSnap.data().PromoCode);
            } else {
                console.log("No link set, user can create one.");
                setIsEditingLink(true); 
            }

            const subscribersRef = collection(firestore, "users"); 
            const q = query(subscribersRef, where("influencerUid", "==", auth.currentUser.uid));
            const querySnapshot = await getDocs(q);
            
            let total = 0;
            let active = 0;

            querySnapshot.forEach((doc) => {
                total++;
                if (doc.data().isSubscribed) {
                    active++;
                }
            });

            setTotalSubscribers(total);
            setActiveSubscribers(active);
        }
    };
  

    const handleLinkChange = (e) => {
        setLinkCustomization(e.target.value);
    };

    const saveCustomLink = async () => {
        const influencerDoc = doc(firestore, "influencers", auth.currentUser.uid);
        const formattedPromoCode = linkCustomization.toLowerCase().replace(/\s/g, '');  // Ensure format consistency
        await updateDoc(influencerDoc, {
            PromoCode: formattedPromoCode,
            uid: auth.currentUser.uid,
            creation: serverTimestamp()
        });
        setOriginalLink(formattedPromoCode); 
        setIsEditingLink(false);
        alert('Link customization saved successfully!');
    };

    const handleCancel = () => {
        setLinkCustomization(originalLink); // Revert to the original link if cancelled
        setIsEditingLink(false);
    };


    return (
        <div>
            <TopBar />
        <div className="dashboard-container">
            <h1 style={{color: 'white'}}>Welcome to Your Dashboard, {user?.name}</h1>
            
                {promoCode && !promoCodeUsed && (
                 <div>
                 <h2 style={{ textAlign: 'center' }}>Your One-Time Promo Code: {promoCode}</h2>
                 <p style={{ textAlign: 'center' }}>Use this code to activate your FREE year membership. (card on file still required)</p>
                 <p style={{ textAlign: 'center' }}>Please enter this during checkout on the app.</p>
                 <a href="https://apps.apple.com/app/id6471882563" target="_blank" rel="noopener noreferrer" className="download-app-button">
                     Download App
                 </a>
             </div>
                )}
            
            <div style={{alignSelf: 'center'}} lassName="link-customization-section">
                    <h2>{isEditingLink ? 'Edit Promo Code' : linkCustomization ? 'Your Active Code' : 'Promo Code Customization'}</h2>
                    <p style={{ textAlign: 'center' }}>This is the PromoCode your followers will use to activate their free month!</p>
                    <p style={{ textAlign: 'center' }}>You can make this whatever you'd like!</p>
                    {!isEditingLink ? (
                        <div style={{flexDirection: 'row'}} className="link-display">
                            <span>{linkCustomization || 'No link set'}</span>
                            <EditIcon onClick={() => setIsEditingLink(true)} className="edit-icon" />
                        </div>
                    ) : (
                        <div className="link-edit">
                            <input type="text" value={linkCustomization} onChange={handleLinkChange} />
                            <button onClick={saveCustomLink} style={{ backgroundColor:  '#4a54e1', color: 'white', border: 'none' }}>Create Code</button>
                            <button onClick={handleCancel} style={{ backgroundColor:  '#4a54e1', color: 'white', border: 'none' }} className="cancel-button">Cancel</button>
                        </div>
                    )}
            </div>
            <div class="stats-container">
                <div class="stats-row">
                    <h2>Total Codes Entered</h2>
                    <p>{totalSubscribers}</p>
                </div>
                <div class="stats-row">
                    <h2>Active Subscribers</h2>
                    <p>{activeSubscribers}</p>
                </div>
                <div class="stats-row">
                    <h2>Est Monthly Payout</h2>
                    <p>${monthlyPayout}</p>
                </div>
                <div class="stats-row">
                    <h2>Est Yearly Payout</h2>
                    <p>${yearlyPayout}</p>
                </div>
                <div class="stats-row">
                    <h2>Withdrawlable Funds</h2>
                    <p>$0.00</p>
                </div>
            </div>
        </div>
        </div>
    );
};

export default Dashboard;




/* 
    const createStripeAccount = async () => {
        const user = auth.currentUser;
        if (user.email) {
            fetch('https://us-central1-yourproject.cloudfunctions.net/createStripeAccount', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    uid: user.uid,  
                    email: user.email
                })
            }).then(response => response.json())
            .then(data => console.log('Stripe account created with ID:', data.stripeAccountId))
            .catch(error => console.error('Error creating Stripe account:', error));
        }
    };
*/