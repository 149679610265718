import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import './BottomTabBar.css';

function BottomTabBar() {
    return (
        <footer className="bottom-tab-bar">
            <div className="left-section">
                © 2024 MonthlyPics LLC. All Rights Reserved.
            </div>
            <div className="right-section">
                {/* <a href="https://twitter.com/monthlypics" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a href="https://instagram.com/monthlypicss" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faInstagram} />
                </a> */}
            </div>
        </footer>
    );
}

export default BottomTabBar;
