import React, { useEffect } from 'react';
import './LandingPage.css';

import HeroSection from './Components/HeroSection';
import FeaturesSection from './Components/FeaturesSection';
import VideoGrid from './Components/VideoGrid';

const LandingPage = () => {

  return (
    <div className="landing-page">
      
    <HeroSection />
    <VideoGrid />
    {/* <FeaturesSection />  */} 

    </div>
  );
};

export default LandingPage;
