import React, { useState } from 'react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { auth, firestore } from '../../firebase/firebaseConfig';
import './SignUpForm.css'

const SignUpForm = ({onClose}) => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        confirmPassword: '',
        name: '',
        accountHandles: [{ handle: '', platform: '' }],
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const handleAccountHandlesChange = (index, e) => {
        const newAccountHandles = [...formData.accountHandles];
        newAccountHandles[index][e.target.name] = e.target.value;
        setFormData({ ...formData, accountHandles: newAccountHandles });
    };

    const addAccountHandle = () => {
        setFormData(prevState => ({
            ...prevState,
            accountHandles: [...prevState.accountHandles, { handle: '', platform: '' }]
        }));
    };

    function modifyEmail(originalEmail) {
        const parts = originalEmail.split('@');
        if (parts.length === 2) { // Ensure the email is valid with exactly one '@'
            return `${parts[0]}1@${parts[1]}`;
        }
        return originalEmail; // Return original if it doesn't meet expected format
    }

    /* const handleSubmit = async (e) => {
        e.preventDefault();
        if (formData.password !== formData.confirmPassword) {
            alert("Passwords do not match!");
            return;
        }

        const { email, password, confirmPassword, ...profileData } = formData;

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            await setDoc(doc(firestore, "influencers", user.uid), profileData);
            alert('Account created successfully!');
        } catch (error) {
            alert(error.message);
        }
    }; */

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formData.password !== formData.confirmPassword) {
            alert("Passwords do not match!");
            return;
        }
    
        const modifiedEmail = modifyEmail(formData.email);
        const { password, confirmPassword, ...profileData } = formData;
    
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, modifiedEmail, password);
            const user = userCredential.user;
            await setDoc(doc(firestore, "influencers", user.uid), {
                ...profileData,
                email: formData.email, // Store the original email in the database
                modifiedEmail: modifiedEmail // Store the modified email as well for reference
            });
            alert('Account created successfully!');
        } catch (error) {
            alert(error.message);
        }
    };

    return (
        <div className="form-container" style={{paddingTop: 30, paddingBottom: 50}}>
            <h2>Signup Details</h2>
            <form onSubmit={handleSubmit}>
                <label style={{alignSelf: 'start'}}>Email</label>
                <input type="email" name="email" value={formData.email} onChange={handleInputChange} required />

                <label style={{alignSelf: 'start'}}>Password</label>
                <input type="password" name="password" value={formData.password} onChange={handleInputChange} required />

                <label style={{alignSelf: 'start'}}>Confirm Password</label>
                <input type="password" name="confirmPassword" value={formData.confirmPassword} onChange={handleInputChange} required />

                <label style={{alignSelf: 'start'}}>Name</label>
                <input type="text" name="name" value={formData.name} onChange={handleInputChange} required />

                {formData.accountHandles.map((accountHandle, index) => (
                    <div style={{alignSelf: 'center'}} key={index} className="platforms-container">

                    <label>Platform</label>
                        <select
                            name="platform"
                            value={accountHandle.platform}
                            onChange={(e) => handleAccountHandlesChange(index, e)}
                            required
                        >
                            <option value="">Select...</option>
                            <option value="TikTok">TikTok</option>
                            <option value="Instagram">Instagram</option>
                            <option value="Pinterest">Pinterest</option>
                            <option value="Other">Other</option>
                        </select>
                        <label>Account Handle</label>
                        <input
                            type="text"
                            name="handle"
                            value={accountHandle.handle}
                            onChange={(e) => handleAccountHandlesChange(index, e)}
                            required
                        />
                    </div>
                ))}

                <button 
                    style={{
                        backgroundColor: 'green',
                        marginTop: -10
                    }}
                    type="button" 
                    onClick={addAccountHandle}>Add Another Handle </button>
                <button type="submit">Sign Up</button>
                <button 
                    style={{
                        backgroundColor: '#224242',
                        marginTop: -10

                }}
                    type="button" 
                    onClick={onClose}>Back</button>
            </form>
        </div>
    );
};

export default SignUpForm;
