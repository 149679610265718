import React from 'react';

import image from '../../Images/Marino.jpg';
import image2 from '../../Images/KM.jpg';



const ImageHost = () => {
    return (
        <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={image} alt="Hosted Image" style={{ maxWidth: '100%', maxHeight: '100%' }} />
            <img src={image2} alt="Hosted Image" style={{ maxWidth: '100%', maxHeight: '100%' }} />

        </div>
    );
};

export default ImageHost;
