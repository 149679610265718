import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase/firebaseConfig';
import MonthlyPicsLogo from '../Images/Group 712.png';
import './TopTabBar.css'; // Import the new CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faInstagram, faTiktok, faBars } from '@fortawesome/free-brands-svg-icons';


function TopTabBar() {

    const [show, setShow] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    const [scrolled, setScrolled] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const toggleMenu = () => setMenuOpen(!menuOpen);
    const closeMenu = () => setMenuOpen(false); // This function will close the menu




    useEffect(() => {
        const handleScroll = () => {
            setMenuOpen(false); // Close the menu when scrolling
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);




    const controlNavbar = () => {
        if (typeof window !== 'undefined') {
            if (window.scrollY > lastScrollY) { 
                setShow(false);
            } else { 
                setShow(true);
            }
            setLastScrollY(window.scrollY);
        }
    };


    return (
        <header className={`header ${scrolled ? 'scrolled' : ''}`}>
        <div className="logo-container">
            <Link to="/">
                <img src={MonthlyPicsLogo} alt="Logo" />
            </Link>
        </div>

        <div 
        style={{marginRight: 40}}
        className="nav-toggle" onClick={toggleMenu}>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
        </div>

        <nav 
        className={`navigation ${menuOpen ? 'expanded' : ''}`}>
                <Link to="/Affiliate" onClick={closeMenu}>Partners</Link>
                <Link to="/faq" onClick={closeMenu}>FAQ</Link>
                <Link to="/contact" onClick={closeMenu}>Contact Us</Link>
            </nav>

        <div 
        style={{marginRight: 30}}
        className="socials-container">
        <a href="https://twitter.com/monthlypics" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon fontSize={20}  color={'white'} icon={faTwitter} />
                </a>
                <a href="https://instagram.com/monthlypicss" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon fontSize={20}  color={'white'} icon={faInstagram} />
                </a>
                <a href="https://www.tiktok.com/@monthlypicss" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon fontSize={20}  color={'white'} icon={faTiktok} />
                </a>
        </div>
    </header>
    
    );
}

export default TopTabBar;


