import React from 'react';
import './FAQPage.css'; // Import the CSS for the FAQ page

const FAQPage = () => {
    return (
        <div className="faq-page">
            <h1 style={{color: 'white'}} >Frequently Asked Questions</h1>
            <div className="faq-item">
                <h2>How does MonthlyPics work?</h2>
                <p>MonthlyPics is a subscription service where you can select three free photos each month. We mail these photos to you, and you can also choose to send one of these pictures to a friend. Both you and your friend will receive a copy of the photo, each framed in high-quality paper frames, free of charge.</p>
            </div>
            <div className="faq-item">
                <h2>What are the costs involved?</h2>
                <p>We charge a shipping and handling fee of $3.99 per month. However, we cover the shipping and handling costs for your first month.</p>
            </div>
            <div className="faq-item">
                <h2>Can I send photos to more than one friend?</h2>
                <p>You can select one photo to send to a friend each month, and both of you will receive a copy. If you wish to send photos to more friends, additional charges may apply.</p>
            </div>
            <div className="faq-item">
                <h2>What if I forget to choose my photos?</h2>
                <p>No worries! We send a notification every month to remind you to choose your photos. This ensures you never miss out on your monthly selections.</p>
            </div>
            <div className="faq-item">
                <h2>What products are available in the in-app store?</h2>
                <p>Our in-app store features a variety of photo-related products, including premium frames and accessories. All items come with free shipping for our subscribers.</p>
            </div>
            <div className="faq-item">
                <h2>Can I gift a MonthlyPics subscription?</h2>
                <p>Yes! While you cannot transfer your subscription directly, you can purchase a gift subscription through our app. It’s a great way to share the joy of memories with loved ones.</p>
            </div>
        </div>
    );
};

export default FAQPage;
