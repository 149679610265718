import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signOut } from "firebase/auth";
import './Settings.css'; // Reusing dashboard styles for uniformity
import TopBar from './TopBar';

const Settings = () => {
    const navigate = useNavigate();
    const auth = getAuth();

    const handleLogout = () => {
        signOut(auth).then(() => {
            navigate("/Affiliate");
        }).catch((error) => {
            console.error('Logout Error:', error);
        });
    };

    return (
        <div>
        <TopBar />
        <div className="dashboard-container" style={{marginTop: -200}}>
            <h1 style={{color: 'white'}}>Settings</h1>
            <div class="stats-container">
                <button
                 style={{width: 150, backgroundColor:  '#4a54e1' }}
                 onClick={handleLogout} className="settings-button logout-button">Log Out</button>
            </div>
            </div>
        </div>
    );
};

export default Settings;
