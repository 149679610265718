import React from 'react';
import './ContactUsPage.css'; // Make sure this imports your updated TikTok-inspired styles

const ContactUsPage = () => {
  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    // Placeholder for form submission logic
    alert('Your message has been sent! We will get back to you soon.');
  };

  return (
    <div className="contact-us-page">
      <h1>Get in Touch</h1>
      <p>Questions, feedback, or just wanna chat? We're all ears!</p>
      <form className="contact-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input type="text" id="name" name="name" placeholder="Your full name" required />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input type="email" id="email" name="email" placeholder="Your email address" required />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message</label>
          <textarea id="message" name="message" placeholder="What's on your mind?" required></textarea>
        </div>
        <button type="submit" className="submit-button">Send Message</button>
      </form>
    </div>
  );
};

export default ContactUsPage;
