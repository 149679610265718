import React, { useEffect } from 'react';
import './ContentGuidelinesNew.css'
import TikTokVideoRow from './TikTokVideoRow';
import TopBar from './TopBar';


const ContentGuidelinesNew = () => {

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://www.tiktok.com/embed.js";
        script.async = true;
        document.body.appendChild(script);
    
        return () => {
          document.body.removeChild(script);
        };
      }, []);

  return (
    <div>
       <TopBar />    
       <TikTokVideoRow /> 
    </div>
 
  );
}

export default ContentGuidelinesNew
