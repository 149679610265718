import React, { useRef, useState } from 'react';
import { firestore, storage, auth } from '../../firebase/firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { DropzoneArea } from 'material-ui-dropzone';

import './ProductUpload.css';

const ProductUpload = () => {
  const [formData, setFormData] = useState({
    SKU: '',
    name: '',
    category: '',
    price: '',
    description: '',
    attributes: { size: '', material: '' },
    stockQuantity: '',
    dimensions: '',
    weight: '',
    variants: []
  });
  const [images, setImages] = useState([]);
  const [uploading, setUploading] = useState(false);




  const onDrop = (acceptedFiles) => {
    const newFiles = acceptedFiles.map(file => ({
      file,
      id: Math.random().toString(36).substr(2, 9),
      preview: URL.createObjectURL(file)
    }));
    setImages(prevImages => [...prevImages, ...newFiles]);
    console.log(newFiles); 
  };


  const handleDragStart = (index) => (event) => {
    event.dataTransfer.setData("text/plain", index);
  };

  const handleDragOver = (index) => (event) => {
    event.preventDefault(); 
  };

  const handleDrop = (index) => (event) => {
    event.preventDefault();
    const movingIndex = event.dataTransfer.getData("text");
    if (movingIndex === index.toString()) return; 

    const itemToMove = images[movingIndex];
    const remainingItems = images.filter((_, idx) => idx.toString() !== movingIndex);
    remainingItems.splice(index, 0, itemToMove);
    setImages(remainingItems);
  };



  const addVariant = () => {
    setFormData({
      ...formData,
      variants: [...formData.variants, { color: '', images: [] }]
    });
  };

  const handleVariantChange = (index, field, value) => {
    const updatedVariants = [...formData.variants];
    updatedVariants[index][field] = value;
    setFormData({ ...formData, variants: updatedVariants });
  };

  const handleColorImages = (index, acceptedFiles) => {
    const newFiles = acceptedFiles.map(file => ({
      file,
      id: Math.random().toString(36).substr(2, 9),
      preview: URL.createObjectURL(file)
    }));

    const updatedVariants = [...formData.variants];
    updatedVariants[index].images = [...updatedVariants[index].images, ...newFiles];
    setFormData({ ...formData, variants: updatedVariants });
  };

  const uploadImages = async (variant) => {
    const imageUrls = await Promise.all(
      variant.images.map(async (item) => {
        const childPath = `productImages/${formData.SKU}/${variant.color}/${item.file.name}_${Date.now()}`;
        const storageRef = ref(storage, childPath);
        const uploadTask = uploadBytesResumable(storageRef, item.file);

        return new Promise((resolve, reject) => {
          uploadTask.on('state_changed',
            () => {},
            (error) => reject(error),
            async () => resolve(await getDownloadURL(uploadTask.snapshot.ref))
          );
        });
      })
    );

    return imageUrls;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploading(true);
  
    try {
      const updatedVariants = await Promise.all(formData.variants.map(async (variant) => {
        const imageUrls = await uploadImages(variant);
        return {
          ...variant,
          images: imageUrls  
        };
      }));
  

      const updatedFormData = {
        ...formData,
        variants: updatedVariants
      };
  

      const docRef = await addDoc(collection(firestore, 'Products'), updatedFormData);
      console.log('Document written with ID: ', docRef.id);
      alert('Product uploaded successfully!');
    } catch (error) {
      console.error("Error processing upload: ", error);
      alert(`Error uploading product: ${error.message}`);
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="form-container">
    <form onSubmit={handleSubmit}>
      <label>SKU</label>
      <input name="SKU" required onChange={(e) => setFormData({...formData, SKU: e.target.value})} />

      <label>Name</label>
      <input name="name" required onChange={(e) => setFormData({...formData, name: e.target.value})} />

      <label>Stripe Price ID</label>
      <input name="Stripe Price ID" required onChange={(e) => setFormData({...formData, priceId: e.target.value})} />

      <label>Category</label>
      <input name="category" required onChange={(e) => setFormData({...formData, category: e.target.value})} />

      <label>Price</label>
      <input type="number" name="price" step="0.01" required onChange={(e) => setFormData({...formData, price: e.target.value})} />

      <label>Description</label>
      <textarea name="description" required onChange={(e) => setFormData({...formData, description: e.target.value})} />

      <label>Size</label>
      <input name="size" required onChange={(e) => setFormData({...formData, attributes: {...formData.attributes, size: e.target.value}})} />

      <label>Material</label>
      <input name="material" required onChange={(e) => setFormData({...formData, attributes: {...formData.attributes, material: e.target.value}})} />

      <label>Stock Quantity</label>
      <input type="number" name="stockQuantity" required onChange={(e) => setFormData({...formData, stockQuantity: e.target.value})} />

      <label>Dimensions</label>
      <input name="dimensions" required onChange={(e) => setFormData({...formData, dimensions: e.target.value})} />

      <label>Weight</label>
      <input name="weight" required onChange={(e) => setFormData({...formData, weight: e.target.value})} />

        {/* <div>
          {images.map((image, index) => (
            <div
              key={image.id}
              draggable
              onDragStart={handleDragStart(index)}
              onDragOver={handleDragOver(index)}
              onDrop={handleDrop(index)}
              style={{ padding: '10px', border: '1px solid black', marginBottom: '5px' }}
            >
              <img src={image.preview} alt={image.file.name} style={{ width: '100px' }} />
              {image.file.name}
            </div>
          ))}
        </div> */}

        {formData.variants.map((variant, index) => (
          <div key={index}>
            <label>Color</label>
            <input
              value={variant.color}
              onChange={e => handleVariantChange(index, 'color', e.target.value)}
              required
            />

            <DropzoneArea
              onChange={files => handleColorImages(index, files)}
              acceptedFiles={['image/*']}
              dropzoneText={`Drag and drop images for ${variant.color} or click`}
              filesLimit={6}
              maxFileSize={10 * 1024 * 1024} 
            />

            {variant.images.map(image => (
              <img key={image.id} src={image.preview} alt={image.file.name} style={{ width: '100px' }} />
            ))}
          </div>
        ))}
        <button type="button" onClick={addVariant}>Add Color Variant</button>

        <button type="submit" disabled={uploading} style={{marginTop: 15}}>
          {uploading ? 'Uploading...' : 'Submit Product'}
        </button>
        
      </form>
    </div>
  );
};

export default ProductUpload;